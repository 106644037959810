// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "owl.carousel";
import "slick-carousel";

// custom

jQuery(document).ready(function () {

    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    } else {
        document.documentElement.className += " touch";
    }

    if (document.documentMode === 10) {
        document.documentElement.className += " ie10";
    }
    else if (document.documentMode === 11) {
        document.documentElement.className += " ie11";
    }

    jQuery(".main-navigation ul li").has("ul").addClass("menu-item-has-children");
    jQuery(".touch .main-navigation ul li.menu-item-has-children > a").on("click touchstart", function (event) {
        if (!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0) {
            jQuery(this).parent().find("li").removeClass("open");
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().addClass("open").siblings().removeClass("open");
        }
    });

    jQuery(".main-navigation  li.menu-item-has-children > a").after(" <div class='child-trigger'><i></i></div>");
    jQuery(".mobile-trigger").click(function () {
        jQuery("body").toggleClass("mobile-open");
        jQuery(".child-trigger").removeClass("child-open");
        return false;
    });

    jQuery(".child-trigger").click(function () {
        jQuery(this).parent().siblings(".menu-item-has-children").find(".child-trigger").removeClass("child-open");
        jQuery(this).parent().siblings(".menu-item-has-children").find(".sub-menu").slideUp(250);
        jQuery(this).toggleClass("child-open");
        jQuery(this).next(".sub-menu").slideToggle(250);
        return false;
    });

    jQuery(".banner-slider").slick( {
        dots: (jQuery(".banner-slider .banner-slide").length > 1) ? true : false,
        arrows: false,
        autoplay:true,
        autoplaySpeed: 8000,
        infinite: true,
    });

    jQuery(".arrival-slider").slick( {
        dots: false,
        infinite: true,
        autoplay:true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll:1
                }
            },

            {
                breakpoint: 300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll:1
                }
            },
        ]
    });

    // For Search by catalog and search by site
    jQuery("#serach-form").on("submit", function (event) {

        if (jQuery("#search-catalog").is(":checked")) {
            event.preventDefault();
            window.open(`https://mbcpl.tlcdelivers.com:8480/?config=sysm#section=search&term=${encodeURIComponent(jQuery("#search_box").val())}`, "_blank");
            return false;
        }

    });
});
